import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
const HomeSlider = () => {
  return (
    <>
      <div className="pagination-home flex justify-center items-center"></div>

      <Swiper
        pagination={{
          el: ".pagination-home",
          clickable: true,
        }}
        navigation={{
          prevEl: ".prev-homeslider",
          nextEl: ".next-homeslider",
        }}
        modules={[Pagination, Navigation]}
        className="homeSwiper"
        slidesPerView={3}
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
      >
        <div className="navigation-home">
          <div className="prev-homeslider cursor-pointer">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.3584 11L1.3584 6L6.3584 1"
                stroke="#6E80DE"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="prev-homeslider cursor-pointer">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.2168 11L6.2168 6L1.2168 1"
                stroke="#6E80DE"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <SwiperSlide>
          <div className="text-left p-[24px] rounded-[8px] card-shadow my-5 mx-[42px]">
            <div className="w-[60px] mb-[28px] mx-auto">
              <img src="/park.svg" alt="park" className="w-full" />
            </div>
            <h3 className="text-blue text-[20px] font-bold text-left mb-[24px]">
              A Day at the Park
            </h3>
            <p>
              I went to the park with my friends. We played on the swings and
              had fun. After, we sat on the grass and ate snacks.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="text-left p-[24px] rounded-[8px] card-shadow my-5 mx-[42px]">
            <div className="w-[60px] mb-[28px] mx-auto">
              <img src="/park.svg" alt="park" className="w-full" />
            </div>
            <h3 className="text-blue text-[20px] font-bold text-left mb-[24px]">
              A Day at the Park
            </h3>
            <p>
              I went to the park with my friends. We played on the swings and
              had fun. After, we sat on the grass and ate snacks.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="text-left p-[24px] rounded-[8px] card-shadow my-5 mx-[42px]">
            <div className="w-[60px] mb-[28px] mx-auto">
              <img src="/park.svg" alt="park" className="w-full" />
            </div>
            <h3 className="text-blue text-[20px] font-bold text-left mb-[24px]">
              A Day at the Park
            </h3>
            <p>
              I went to the park with my friends. We played on the swings and
              had fun. After, we sat on the grass and ate snacks.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="text-left p-[24px] rounded-[8px] card-shadow my-5 mx-[42px]">
            <div className="w-[60px] mb-[28px] mx-auto">
              <img src="/park.svg" alt="park" className="w-full" />
            </div>
            <h3 className="text-blue text-[20px] font-bold text-left mb-[24px]">
              A Day at the Park
            </h3>
            <p>
              I went to the park with my friends. We played on the swings and
              had fun. After, we sat on the grass and ate snacks.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
export default HomeSlider;
