import React, { createContext, useContext, useState } from "react";

const SessionContext = createContext();

export const useSessionContext = () => {
  return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
  const [report, setReport] = useState(null);
  const [scoreReportNumber, setScoreReportNumber] = useState(null);
  const [collectionOfPronounceResult, setCollectionOfPronounceResult] =
    useState(null);
  const [evaluationSummary, setEvaluationSummary] = useState("");
  // const [evaluationSummary, setEvaluationSummary] = useState("");

  const [recognizeWordResult, setRecognizeWordResult] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [textToBeRead, setTextToBeRead] = useState(
    "I went to the park with my friends. We played on the swings and had fun. After, we sat on the grass and ate snacks."
  );

  const [isReasses, setIsReasses] = useState(false);

  return (
    <SessionContext.Provider
      value={{
        report,
        setReport,
        textToBeRead,
        setTextToBeRead,
        audioBlob,
        setAudioBlob,
        recognizeWordResult,
        setRecognizeWordResult,
        scoreReportNumber,
        setScoreReportNumber,
        collectionOfPronounceResult,
        setCollectionOfPronounceResult,
        evaluationSummary,
        setEvaluationSummary,
        isReasses,
        setIsReasses,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
