import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import React, { useState, useEffect, useRef } from "react";
import { useSessionContext } from "./context/sessionContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AssessPerformance = () => {
  const {
    textToBeRead,
    setTextToBeRead,
    report,
    setReport,
    audioBlob,
    scoreReportNumber,
    setScoreReportNumber,
    evaluationSummary,
    isReasses,
    setIsReasses,
  } = useSessionContext();

  const [isListeningRecord, setIsListeningRecord] = useState(false);
  const [audioElement, setAudioElement] = useState(null);

  const [isSpeaking, setIsSpeaking] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(-1);

  const [isItPerfect, setIsitPerfect] = useState(true);
  const [kindOfErrors, setKindOfErrors] = useState([]);

  const navigate = useNavigate();

  const data = {
    labels: ["good", "bad"],
    datasets: [
      {
        data: [
          scoreReportNumber?.pronScore?.toFixed(0),
          100 -
            (scoreReportNumber?.pronScore == null
              ? 0
              : scoreReportNumber?.pronScore?.toFixed(0)),
        ],
        backgroundColor: ["#3C4ED0", "#D9D9D9"],
        cutout: 60,
        borderRadius: 3,
      },
    ],
  };

  const playRecording = () => {
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
      setAudioElement(audio);
      setIsListeningRecord(true);
    }
  };

  const stopRecording = () => {
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
      setIsListeningRecord(false);
    }
  };

  const startReading = () => {
    setIsSpeaking(true);
    const utterance = new SpeechSynthesisUtterance(textToBeRead);
    const words = textToBeRead.split(" ");

    // On each boundary, we calculate the current spoken word
    utterance.onboundary = (event) => {
      if (event.name === "word") {
        const charIndex = event.charIndex;
        const spokenWords = textToBeRead.slice(0, charIndex).split(" ");
        setCurrentWordIndex(spokenWords.length - 1);
      }
    };

    // Once speech ends, reset the index
    utterance.onend = () => {
      setCurrentWordIndex(-1);
      setIsSpeaking(false);
    };

    window.speechSynthesis.speak(utterance);
  };
  const stopReading = () => {
    window.speechSynthesis.cancel();
    setIsSpeaking(false);
    setCurrentWordIndex(-1);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onCreateNewPassageWithWord = () => {
    let troubleWords = [];

    report.map((content) => {
      if (
        content?.PronunciationAssessment?.ErrorType !== "None" &&
        content?.PronunciationAssessment?.ErrorType !== "Insertion"
      ) {
        troubleWords.push(content.Word);
      }
    });

    if (troubleWords.length == 0) {
      troubleWords = ["Went", "Played", "Grass"];
    }

    return axios
      .post(
        "https://fa-hackacthon-pronunciation-assessment-squad6.azurewebsites.net/api/passage_generation_api?code=GFyMQHws-Q18HXUD-PuL7_0y9uoL4VzyFuRzIp4anDZ5AzFuY5upuQ%3D%3D",
        { words: troubleWords }
      )
      .then((resp) => {
        if (resp.status == "200") {
          console.log(resp);
          setTextToBeRead(resp?.data?.sentence);
          setIsReasses(true);
          navigate("/listening");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setKindOfErrors([]);
    report.map((content) => {
      if (content?.PronunciationAssessment?.ErrorType !== "None") {
        setIsitPerfect(false);
        setKindOfErrors((prevItems) => {
          const itemIndex = prevItems.findIndex(
            (item) => item.name === content?.PronunciationAssessment?.ErrorType
          );

          if (itemIndex !== -1) {
            // Item exists, increment count
            const updatedItems = [...prevItems];
            updatedItems[itemIndex].count += 1;
            return updatedItems;
          } else {
            // Item doesn't exist, add new item with count 1
            return [
              ...prevItems,
              { name: content?.PronunciationAssessment?.ErrorType, count: 1 },
            ];
          }
        });
      }
    });
  }, []);

  return (
    <section className="w-[1190px] mx-auto my-[100px]">
      <h1 className="text-blue font-bold text-[32px] mb-10">
        {isReasses ? "Retake Performance Summary" : "Pronunciation Progress"}
      </h1>
      <div className="flex flex-col gap-7">
        {/* row 1 */}
        <div className="flex items-stretch gap-7">
          <div className="bg-white card-shadow px-10 py-6 w-[507px] shrink-0 rounded-2xl">
            <h4 className="text-[16px] text-black font-medium mb-6">
              Overview
            </h4>
            <div className="flex justify-between items-start shrink-0">
              <div className="relative w-[170px] h-[170px] flex shrink-0 items-center justify-center">
                <h3 className="text-blue font-bold text-[48px] absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
                  {scoreReportNumber?.pronScore?.toFixed(0)}%
                </h3>
                <Doughnut
                  data={data}
                  options={{
                    aspectRatio: 1,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>

              <div className="progress">
                <div className="mb-4">
                  <div className="flex justify-between">
                    <h4 className="text-blue font-semibold text-[16px] mb-2 leading-[19px]">
                      Accuracy score
                    </h4>
                    <h4 className="text-blue font-semibold text-[16px] mb-2 leading-[19px]">
                      {scoreReportNumber?.accuracyScore?.toFixed(0)}%
                    </h4>
                  </div>

                  <div className="outer-progress">
                    <div
                      className="inner-progress"
                      style={{ width: scoreReportNumber?.accuracyScore + "%" }}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex justify-between">
                    <h4 className="text-blue font-semibold text-[16px] mb-2 leading-[19px]">
                      Fluency score
                    </h4>
                    <h4 className="text-blue font-semibold text-[16px] mb-2 leading-[19px]">
                      {scoreReportNumber?.fluencyScore?.toFixed(0)}%
                    </h4>
                  </div>

                  <div className="outer-progress">
                    <div
                      className="inner-progress"
                      style={{ width: scoreReportNumber?.fluencyScore + "%" }}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex justify-between">
                    <h4 className="text-blue font-semibold text-[16px] mb-2 leading-[19px]">
                      Completeness score
                    </h4>
                    <h4 className="text-blue font-semibold text-[16px] mb-2 leading-[19px]">
                      {scoreReportNumber?.compScore?.toFixed(0)}%
                    </h4>
                  </div>

                  <div className="outer-progress">
                    <div
                      className="inner-progress"
                      style={{ width: scoreReportNumber?.compScore + "%" }}
                    />
                  </div>
                </div>
                <div className="mb-0">
                  <div className="flex justify-between">
                    <h4 className="text-blue font-semibold text-[16px] mb-2 leading-[19px]">
                      Prosody score
                    </h4>
                    <h4 className="text-blue font-semibold text-[16px] mb-2 leading-[19px]">
                      {scoreReportNumber?.prosodyScore?.toFixed(0)}%
                    </h4>
                  </div>

                  <div className="outer-progress">
                    <div
                      className="inner-progress"
                      style={{ width: scoreReportNumber?.prosodyScore + "%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white card-shadow p-6 rounded-2xl">
            <h4 className="text-[16px] text-black font-medium mb-6">
              Evaluation summary
            </h4>
            <p className="text-black">
              You did a great job with your pronunciation! Most of your words
              were accurate, and you spoke smoothly. You said all the parts of
              each word, but a few sounds need a little more practice to make
              them clearer. Your voice had a nice rhythm, and you used the right
              tone. Keep practicing, and you’ll keep getting better!
              {evaluationSummary}
            </p>
          </div>
        </div>
        {/* row 2 */}
        <div className="flex items-stretch gap-7">
          <div className="bg-white card-shadow p-6 rounded-2xl flex-grow">
            <h4 className="text-[16px] text-black font-medium mb-6">
              Errors Found
            </h4>
            <div className="text-black grid grid-cols-2 gap-6">
              <div className="w-[168px] shrink-0 flex gap-6 flex-col">
                {kindOfErrors?.map((content, index) => {
                  return (
                    <div className="flex gap-[8px] items-center justify-start">
                      <span
                        className={`${
                          content?.name == "Mispronunciation"
                            ? "bg-light-orange"
                            : content?.name == "Insertion"
                            ? "bg-orange"
                            : "bg-light-pink"
                        } bg-error-color`}
                      >
                        {content?.count}
                      </span>{" "}
                      {content?.name}
                    </div>
                  );
                })}

                {/* 
                <div className="flex gap-[8px] items-center justify-start">
                  <span className="bg-light-orange bg-error-color">1.</span>{" "}
                  Mispronunciation
                </div>
                <div className="flex gap-[8px] items-center justify-start">
                  <span className="bg-light-pink bg-error-color">2.</span>{" "}
                  Ommission
                </div>

                <div className="flex gap-[8px] items-center justify-start">
                  <span className="bg-orange bg-error-color">3.</span>{" "}
                  Insertions
                </div>
                <div className="flex gap-[8px] items-center justify-start">
                  <span className="bg-light-green bg-error-color">4.</span>{" "}
                  Unexpected break
                </div>
                
                */}
              </div>
              {/* 
              
              <div className="flex gap-6 flex-col">
                <div className="flex gap-[8px] items-center justify-start">
                  <span className="bg-light-gray bg-error-color">5.</span>{" "}
                  Missing break
                </div>
                <div className="flex gap-[8px] items-center justify-start">
                  <span className="bg-pink bg-error-color">6.</span> Monotone
                </div>
              </div>
              
              */}
            </div>
          </div>
          <div className="bg-white card-shadow px-10 py-6 w-[775px] shrink-0 rounded-2xl">
            <h4 className="text-[16px] text-black font-medium mb-6">
              Your Recording
            </h4>
            <h3 className="text-[20px] text-blue font-bold mb-8">
              A Day at the Park
            </h3>
            <p className="text-black">
              {report?.map((content) => {
                return (
                  <>
                    <span
                      className={`${
                        content?.PronunciationAssessment?.ErrorType ==
                        "Insertion"
                          ? "bg-orange "
                          : content?.PronunciationAssessment?.ErrorType ==
                            "Omission"
                          ? "bg-light-pink "
                          : content?.PronunciationAssessment?.ErrorType ==
                            "Mispronunciation"
                          ? "bg-light-orange "
                          : ""
                      }`}
                    >
                      {content?.Word}{" "}
                    </span>{" "}
                  </>
                );
              })}
            </p>

            <div className="mt-8">
              <div className="flex gap-4 items-center justify-between">
                <div className="h-[11px] bg-[#F2F3F7] flex-grow"></div>
                <div
                  className="w-10 h-10 rounded-full bg-[#F2F3F7]  flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    isListeningRecord ? stopRecording() : playRecording()
                  }
                >
                  {isListeningRecord ? (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2876 1V11C11.2876 11.2652 11.1822 11.5196 10.9947 11.7071C10.8072 11.8946 10.5528 12 10.2876 12H7.7876C7.52238 12 7.26803 11.8946 7.08049 11.7071C6.89295 11.5196 6.7876 11.2652 6.7876 11V1C6.7876 0.734784 6.89295 0.48043 7.08049 0.292893C7.26803 0.105357 7.52238 0 7.7876 0H10.2876C10.5528 0 10.8072 0.105357 10.9947 0.292893C11.1822 0.48043 11.2876 0.734784 11.2876 1ZM3.7876 0H1.2876C1.02238 0 0.768027 0.105357 0.580491 0.292893C0.392954 0.48043 0.287598 0.734784 0.287598 1V11C0.287598 11.2652 0.392954 11.5196 0.580491 11.7071C0.768027 11.8946 1.02238 12 1.2876 12H3.7876C4.05281 12 4.30717 11.8946 4.4947 11.7071C4.68224 11.5196 4.7876 11.2652 4.7876 11V1C4.7876 0.734784 4.68224 0.48043 4.4947 0.292893C4.30717 0.105357 4.05281 0 3.7876 0Z"
                        fill="#3C4ED0"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="11"
                      height="14"
                      viewBox="0 0 11 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 7.00007C11.0004 7.16983 10.9569 7.33682 10.8736 7.48478C10.7904 7.63274 10.6703 7.75665 10.525 7.84444L1.52 13.3532C1.36818 13.4462 1.19429 13.4969 1.0163 13.5002C0.838305 13.5035 0.662659 13.4592 0.5075 13.3719C0.353819 13.286 0.225798 13.1607 0.136602 13.0089C0.0474072 12.8571 0.000256786 12.6843 0 12.5082V1.49194C0.000256786 1.31587 0.0474072 1.14305 0.136602 0.991237C0.225798 0.83943 0.353819 0.714119 0.5075 0.628193C0.662659 0.540911 0.838305 0.496631 1.0163 0.499927C1.19429 0.503223 1.36818 0.553976 1.52 0.646943L10.525 6.15569C10.6703 6.24349 10.7904 6.36739 10.8736 6.51535C10.9569 6.66332 11.0004 6.8303 11 7.00007Z"
                        fill="#3C4ED0"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* row 3 */}
        <div className="flex items-stretch gap-7">
          {isItPerfect ? (
            <div className="bg-white card-shadow p-6 rounded-2xl flex-grow">
              <h3 className="text-[20px] text-blue font-bold mb-8">
                Keep Going!
              </h3>
              <p className="text-black">
                You're doing an amazing job improving your pronunciation. Every
                time you practice, you get better and stronger. Keep going—try
                the next passage and continue your progress!
              </p>
              <div className="mt-[43px]">
                <button
                  className="w-full py-3 text-blue text-[16px] font-medium rounded-[4px] border-blue border-[0.5px]"
                  onClick={() => onCreateNewPassageWithWord()}
                >
                  Create Passage with Word
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white card-shadow p-6 rounded-2xl flex-grow">
              <h4 className="text-[16px] text-black font-medium mb-6">
                Troubled Words
              </h4>
              <div className="flex flex-col gap-6">
                {report?.map((content, index) => {
                  if (
                    content?.PronunciationAssessment?.ErrorType !== "None" &&
                    content?.PronunciationAssessment?.ErrorType !== "Insertion"
                  ) {
                    return (
                      <div className="text-[16px] font-medium flex gap-2 text-black">
                        {content?.counterLoop}.{" "}
                        {capitalizeFirstLetter(content?.Word)} -{" "}
                        {content?.PronunciationAssessment?.ErrorType}
                      </div>
                    );
                  }
                })}

                {/* 
              <div className="text-[16px] font-medium flex gap-2 text-black">
                1. Went - <span className="text-[#B1B1B1]">/wɛnt/</span>{" "}
                <img src="/audio.svg" alt="audio" />
              </div>
              <div className="text-[16px] font-medium flex gap-2 text-black">
                2. Played - <span className="text-[#B1B1B1]">/pleɪd/</span>{" "}
                <img src="/audio.svg" alt="audio" />
              </div>
              <div className="text-[16px] font-medium flex gap-2 text-black">
                3. Grass - <span className="text-[#B1B1B1]">/ɡræs/</span>{" "}
                <img src="/audio.svg" alt="audio" />
              </div>
              
              */}
              </div>

              <div className="mt-[43px]">
                <button
                  className="w-full py-3 text-blue text-[16px] font-medium rounded-[4px] border-blue border-[0.5px]"
                  onClick={() => onCreateNewPassageWithWord()}
                >
                  Create Passage with Word
                </button>
              </div>
            </div>
          )}
          <div className="bg-white card-shadow px-10 py-6 w-[775px] shrink-0 rounded-2xl">
            <h4 className="text-[16px] text-black font-medium mb-6">
              Original Script
            </h4>
            <h3 className="text-[20px] text-blue font-bold mb-8">
              A Day at the Park
            </h3>
            <p className="text-black">
              {textToBeRead.split(" ").map((word, index) => (
                <span
                  key={index}
                  style={{
                    backgroundColor:
                      index === currentWordIndex ? "yellow" : "transparent",
                  }}
                >
                  {word}{" "}
                </span>
              ))}
            </p>

            <div className="mt-8">
              <div className="flex gap-4 items-center justify-between">
                <div className="h-[11px] bg-[#F2F3F7] flex-grow"></div>
                <div className="w-10 h-10 rounded-full bg-light-gray flex justify-center items-center">
                  {isSpeaking ? (
                    <div
                      className="bg-[#F2F3F7] rounded-full flex justify-center items-center w-10 h-10 cursor-pointer"
                      onClick={() => stopReading()}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2876 1V11C11.2876 11.2652 11.1822 11.5196 10.9947 11.7071C10.8072 11.8946 10.5528 12 10.2876 12H7.7876C7.52238 12 7.26803 11.8946 7.08049 11.7071C6.89295 11.5196 6.7876 11.2652 6.7876 11V1C6.7876 0.734784 6.89295 0.48043 7.08049 0.292893C7.26803 0.105357 7.52238 0 7.7876 0H10.2876C10.5528 0 10.8072 0.105357 10.9947 0.292893C11.1822 0.48043 11.2876 0.734784 11.2876 1ZM3.7876 0H1.2876C1.02238 0 0.768027 0.105357 0.580491 0.292893C0.392954 0.48043 0.287598 0.734784 0.287598 1V11C0.287598 11.2652 0.392954 11.5196 0.580491 11.7071C0.768027 11.8946 1.02238 12 1.2876 12H3.7876C4.05281 12 4.30717 11.8946 4.4947 11.7071C4.68224 11.5196 4.7876 11.2652 4.7876 11V1C4.7876 0.734784 4.68224 0.48043 4.4947 0.292893C4.30717 0.105357 4.05281 0 3.7876 0Z"
                          fill="#3C4ED0"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div
                      className="bg-[#F2F3F7] rounded-full flex justify-center items-center w-10 h-10 cursor-pointer"
                      onClick={() => startReading()}
                    >
                      <svg
                        width="11"
                        height="14"
                        viewBox="0 0 11 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 7.00007C11.0004 7.16983 10.9569 7.33682 10.8736 7.48478C10.7904 7.63274 10.6703 7.75665 10.525 7.84444L1.52 13.3532C1.36818 13.4462 1.19429 13.4969 1.0163 13.5002C0.838305 13.5035 0.662659 13.4592 0.5075 13.3719C0.353819 13.286 0.225798 13.1607 0.136602 13.0089C0.0474072 12.8571 0.000256786 12.6843 0 12.5082V1.49194C0.000256786 1.31587 0.0474072 1.14305 0.136602 0.991237C0.225798 0.83943 0.353819 0.714119 0.5075 0.628193C0.662659 0.540911 0.838305 0.496631 1.0163 0.499927C1.19429 0.503223 1.36818 0.553976 1.52 0.646943L10.525 6.15569C10.6703 6.24349 10.7904 6.36739 10.8736 6.51535C10.9569 6.66332 11.0004 6.8303 11 7.00007Z"
                          fill="#3C4ED0"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AssessPerformance;
