import React, { useEffect, useState } from 'react'
import HomeSlider from "./components/HomeSlider";
import "./App.css";
import { Link } from "react-router-dom";
import { useSessionContext } from "./context/sessionContext";

function App() {

  const { textToBeRead, setTextToBeRead } = useSessionContext();

  useEffect(() => {
  }, [])


  return (
    <section className="min-h-screen flex flex-col justify-center items-center">
      <h2 className="text-blue text-4xl text-center font-rubik font-bold">
        Choose a Passage
      </h2>
      <HomeSlider />
      <div className="mt-[35px]">
        <Link to={"/listening"} className="btn btn-blue">
          Start listening!
        </Link>
      </div>
    </section>
  );
}

export default App;
